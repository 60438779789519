import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-coach-info',
  templateUrl: './coach-info.component.html',
  styleUrls: ['./coach-info.component.css']
})
export class CoachInfoComponent implements OnInit {

  constructor(private route: Router, private Auth: AuthService, private router: ActivatedRoute, private api: ApiService, private notify: SnotifyService) { }

  coach= null;
  userData = null;

  public loggedIn: boolean;

  adverts = null;

  ngOnInit() {

    this.api.getAdverts().subscribe(res => this.adverts = res, error => console.log(error.error.error));

    this.Auth.authStatus.subscribe(value => this.loggedIn = value);

    let coachId = this.router.snapshot.params.coachId;
    this.userData = JSON.parse(localStorage.getItem('user'));
    this.api.getUserInfo('coach', coachId).subscribe(response => {
      this.coach = response;
      console.log(this.coach);
    }, error=> {
      this.notify.error(error.error.error, {timeout: 3000});
    });

  }

  openChat(event) {
    event.preventDefault();

    let user = JSON.parse(localStorage.getItem('user'));
    let data = {
      user_id: user.id,
      user_name: user.first_name+' '+user.last_name,
      user_type: user.user_type,
      receiver_id: this.coach.user_id,
      receiver_name: this.coach.first_name+' '+this.coach.last_name,
      receiver_image: this.coach.image,

    }

    this.api.openChat(data).subscribe(res => {
      this.route.navigateByUrl('/chat');
    }, error => console.log(error));
  }

}
