import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-coach',
  templateUrl: './coach.component.html',
  styleUrls: ['./coach.component.css']
})
export class CoachComponent implements OnInit {

  constructor(private api: ApiService, private notify: SnotifyService) { }

  coaches = null;
  coachesResult = null;

  ngOnInit() {
    this.api.getCoaches().subscribe((response) => {


      this.coachesResult = response;
      this.coaches = response;

    }, (error) => {
      this.notify.error(error.error.error, {timeout: 3000});
    });
  }

  search = {
    key: '',
    type: 'last_name'
  }

  changeType(type) {
    this.search.type = type;
  }

  searchUsers() {
    let results = [];

    if(this.search.key == '') {
      results = this.coaches;
      this.coachesResult = results;
      return false;
    }

    for (let index = 0; index < this.coaches.length; index++) {
      let element = this.coaches[index];

      if(this.search.type == 'title') {

        if(element.title !== null) {
          if(element.title.toLowerCase().includes(this.search.key.toLowerCase())) {
            results.push(element);
          }
        }

      }

      if(this.search.type == 'last_name') {
        if(element.last_name !== null) {
          if(element.last_name.toLowerCase().includes(this.search.key.toLowerCase())) {
            results.push(element);
          }
        }

      }

      if(this.search.type == 'country') {

        if(element.country !== null) {
          if(element.country.toLowerCase().includes(this.search.key.toLowerCase())) {
            results.push(element);
          }
        }

      }

      if(this.search.type == 'sports') {
        if(element.sports !== null) {
          if(element.sports.toLowerCase().includes(this.search.key.toLowerCase())) {
            results.push(element);
          }
        }


      }


    }

    this.coachesResult = results;
  }

}
