import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-player-info',
  templateUrl: './player-info.component.html',
  styleUrls: ['./player-info.component.css']
})
export class PlayerInfoComponent implements OnInit {

  constructor(private route: Router, private Auth: AuthService, private router: ActivatedRoute, private api: ApiService, private notify: SnotifyService) { }
  player = null;
  userData = null;
  public loggedIn: boolean;

  adverts = null;

  ngOnInit() {

    this.api.getAdverts().subscribe(res => this.adverts = res, error => console.log(error.error.error));

    this.Auth.authStatus.subscribe(value => this.loggedIn = value);
    let playerId = this.router.snapshot.params.playerId;
    this.userData = JSON.parse(localStorage.getItem('user'));

    this.api.getUserInfo('player', playerId).subscribe(response => {
      this.player = response;
      console.log(this.player);
    }, error=> {
      this.notify.error(error.error.error, {timeout: 3000});
    });
  }

  openChat(event) {
    event.preventDefault();

    let user = JSON.parse(localStorage.getItem('user'));
    let data = {
      user_id: user.id,
      user_name: user.first_name+' '+user.last_name,
      user_type: user.user_type,
      receiver_id: this.player.user_id,
      receiver_name: this.player.first_name+' '+this.player.last_name,
      receiver_image: this.player.image,

    }

    this.api.openChat(data).subscribe(res => {
      this.route.navigateByUrl('/chat');
    }, error => console.log(error));
  }

}
