import { Component } from '@angular/core';
import { Router, Event } from '@angular/router';
import { NavigationStart, NavigationError, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'jocklify';
  loading = false;
  year = new Date().getFullYear();
  currentUrl = '';


  constructor(private router: Router) {



    //Router subscriber
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {

          this.loading = true;
          window.scroll(0,0);
      }

      if (event instanceof NavigationError) {
          // Handle error
          console.error(event.error);
      }

      if (event instanceof NavigationEnd) {
          //do something on end activity

          this.currentUrl = this.router.url.substring(1);
          // this.loading = false;
          setTimeout(() => {
            this.loading = false;
          }, 1000);



      }


    });
  }



}
