import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-add-player-profile',
  templateUrl: './add-player-profile.component.html',
  styleUrls: ['./add-player-profile.component.css']
})
export class AddPlayerProfileComponent implements OnInit {

  loader = false;
  user = null;
  form = {
    playerId: null,
    first_name: null,
    last_name: null,
    user_type: null,
    email: null,
    dob: null,
    country: null,
    city: null,
    sports: null,
    team: null,
    position: null,
    accomplishment: null,
    searching_for: null,
    user_id: null,
    height: null,
    weight: null,
    gender: null,
    video: null,
    website: null,
    linkedin: null,
    title: null,
    level: null
  }


  passwordMatch = true;

  constructor(private notify: SnotifyService, private api: ApiService, private router: Router, private Auth: AuthService) { }

  adverts = null;
  ngOnInit() {

    this.api.getAdverts().subscribe(res => this.adverts = res, error => console.log(error.error.error));

    let userData = JSON.parse(localStorage.getItem('user'));

    this.form.first_name = userData.first_name;
    this.form.last_name = userData.last_name;
    this.form.email = userData.email;
    this.form.user_type = userData.user_type;
    this.form.user_id = userData.id;

    this.api.getPlayerProfile(userData.id).subscribe((response) => {

      this.setData(response);

    });

  }

  setData(response) {
      this.form.playerId = response.id,
      this.form.dob = response.dob;
      this.form.country = response.country;
      this.form.city = response.city;
      this.form.sports = response.sports;
      this.form.team = response.team;
      this.form.position = response.position;
      this.form.height = response.height;
    this.form.weight = response.weight;
    this.form.gender = response.gender;
    this.form.video = response.video;
    this.form.website = response.website;
    this.form.linkedin = response.linkedin;
    this.form.title = response.title;
    this.form.level = response.level;
      if(response.image != null) {
        this.previewUrl = 'https://backend.jockify.com/'+response.image;
      }
  }


  fileData: File = null;
  previewUrl:any = "assets/images/profile.jpg";
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;


  fileProgress(fileInput: any) {
      this.fileData = <File>fileInput.target.files[0];
      this.preview();
      this.uploadImage()
  }

  preview() {
      // Show preview
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {
        this.previewUrl = reader.result;
      }
  }

  loaderThree = false;

  uploadImage() {
    if(!this.fileData) {
      this.notify.warning('No Image Selected', {timeout: 3000});
      return false;
    }

    this.loaderThree = true;
    const formData = new FormData();
    formData.append('image', this.fileData);
    formData.append('user_type', this.form.user_type);
    formData.append('user_id', this.form.user_id);

    this.api.uploadImage(formData).subscribe(res => {
      console.log(res);
      // this.uploadedFilePath = res.data.filePath;
      this.loaderThree = false;
      // this.notify.success('Upload Successful', {timeout: 3000});
    }, error => {
      // this.notify.error(error.error.error, {timeout: 3000});
      this.loaderThree = false;

      if(error.status == 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.Auth.changeAuthStatus(false);
        this.router.navigateByUrl('/login');
      }
    });
  }

  selectFile() {
    document.getElementById('profilePicture').click();
  }

  submit(data) {
    this.loader = true;
    console.log(data);
    this.api.updatePlayerProfile(this.form).subscribe((response) => {
      this.loader= false;
      this.router.navigateByUrl('/player-info/'+this.form.playerId);
      // this.notify.success('Profile Update successful', {timeout: 3000});
    }, (error) => {
      this.loader= false

      if(error.status == 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.Auth.changeAuthStatus(false);
        this.router.navigateByUrl('/login');
      }

      // this.notify.error(error.error.error, {timeout: 3000});
    });;
  }

  loaderTwo = false;

  changePassword(data) {
    this.loaderTwo= true;
    if(data.new_password == data.r_password) {
      this.passwordMatch = true;

      data.user_id = this.form.user_id;

      this.api.changeUserPassword(data).subscribe((response) => {
        this.loaderTwo= false;
        alert('Password successfuly changed');
        // this.notify.success('Password successfuly changed', {timeout: 3000});
      }, (error) => {
        alert('Current password is incorrect');
        this.loaderTwo= false
        if(error.status == 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          this.Auth.changeAuthStatus(false);
          this.router.navigateByUrl('/login');
        }
        // this.notify.error(error.error.error, {timeout: 3000});
      });

    }
    else{
      this.passwordMatch = false;
      this.loaderTwo = false;
      alert('Passwords do not match');
    }
  }

}
