import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { FaqComponent } from './faq/faq.component';
import { HelpComponent } from './help/help.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AddProfileComponent } from './add-profile/add-profile.component';
import { PlayerComponent } from './player/player.component';
import { CoachComponent } from './coach/coach.component';
import { AboutComponent } from './about/about.component';
import { AdvertComponent } from './advert/advert.component';
import { ForgotComponent } from './forgot/forgot.component';
import { AddPlayerProfileComponent } from './add-player-profile/add-player-profile.component';
import { AddCoachProfileComponent } from './add-coach-profile/add-coach-profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BeforeLoginService } from './before-login.service';
import { AfterLoginService } from './after-login.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PlayerInfoComponent } from './player-info/player-info.component';
import { CoachInfoComponent } from './coach-info/coach-info.component';
import { ChatComponent } from './chat/chat.component';
import { TermsComponent } from './terms/terms.component';
import { PrivateTrainerInfoComponent } from './private-trainer-info/private-trainer-info.component';
import { AddPrivateTrainerComponent } from './add-private-trainer/add-private-trainer.component';
import { PrivateTrainerComponent } from './private-trainer/private-trainer.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'chat',
    component: ChatComponent
  },
  {
    path: 'player-info/:playerId',
    component: PlayerInfoComponent
  },
  {
    path: 'coach-info/:coachId',
    component: CoachInfoComponent
  },
  {
    path: 'trainer-info/:trainerId',
    component: PrivateTrainerInfoComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: 'forgot-password',
    component: ForgotComponent,
    canActivate: [BeforeLoginService]
  },
  {
    path: 'advertisement',
    component: AdvertComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [BeforeLoginService]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [BeforeLoginService]
  },
  {
    path: 'player-profile',
    component: AddPlayerProfileComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: 'coach-profile',
    component: AddCoachProfileComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: 'trainer-profile',
    component: AddPrivateTrainerComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: 'players',
    component: PlayerComponent
  },
  {
    path: 'coaches',
    component: CoachComponent
  },
  {
    path: 'trainers',
    component: PrivateTrainerComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [BeforeLoginService]
  },
  {
  path: 'faq',
  component: FaqComponent
  },
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
