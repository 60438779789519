import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { TokenService } from '../token.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {


  className = 'login';
  emailMessage = false;
  passwordMessage = false;
  registerLoader = false;

  message = null;

  constructor(private api: ApiService, private notify: SnotifyService, private token: TokenService, private router: Router, private Auth: AuthService) { }

  ngOnInit() {
    // if(window.location.href.includes('register')) {
    //   alert('register page')
    // }
    // else {
    //   alert('other pages');
    // }
  }

  tokenObject = {
    access_token: null
  }

  handleResponse(data) {
    this.tokenObject = data;
    this.token.handle(this.tokenObject.access_token);
    this.Auth.changeAuthStatus(true);
    this.router.navigateByUrl('/');
    // window.location.href = '/dashboard';
  }

  submit(data) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(re.test(data.email)) {
      this.emailMessage = false;



      this.registerLoader = true;

      this.api.register(data).subscribe(
        response => {

          this.registerLoader = false;



          let data = response;

          console.log(response)

          this.api.getLoggedInUser(data).subscribe((response) => {

            localStorage.setItem('user', JSON.stringify(response));


            this.handleResponse(data);
            this.registerLoader = false;
          }, (error) => {
            this.registerLoader = false;
            alert(error.error.error);
            // this.notify.error(error.error.error, {timeout: 3000});
          });




        },
        (error) => {
          alert(error.error.error);
          // this.notify.error(error.error.error, {timeout: 3000});
          this.registerLoader = false;
        }
      );



    }
    else {
      this.emailMessage = true;
    }
  }

}
