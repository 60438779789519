import { Component, OnInit } from '@angular/core';
import { Router, Event } from '@angular/router';
import { NavigationStart, NavigationError, NavigationEnd } from '@angular/router';
import { AuthService } from '../auth.service';
import { TokenService } from '../token.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  currentUrl = '';
  public loggedIn: boolean;
  public user = null;

  constructor(private router: Router, private Auth: AuthService, private token: TokenService) {
    let userData = localStorage.getItem('user');

    this.user = JSON.parse(userData);

    this.router.events.subscribe((event: Event) => {




      if (event instanceof NavigationEnd) {
          //do something on end activity

          this.currentUrl = this.router.url.substring(1);

          var element = document.getElementById('rootContainer');
          element.scrollTop = -10;

      }


    });
  }

  editProfile(event) {
    event.preventDefault();

    let user = JSON.parse(localStorage.getItem('user'));

    if(user.user_type == 'player') {
      this.router.navigateByUrl('player-profile')
    }
    if(user.user_type == 'coach') {
      this.router.navigateByUrl('coach-profile');
    }

    if(user.user_type == 'trainer') {
      this.router.navigateByUrl('trainer-profile');
    }
  }

  ngOnInit() {

    this.Auth.authStatus.subscribe(value => this.loggedIn = value);


  }

  logOut(event: MouseEvent) {
    event.preventDefault();
    this.token.remove();
    localStorage.removeItem('user');
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/login');
  }

}
