import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  constructor(private api: ApiService) { }

  adverts = null;

  ngOnInit() {
    this.api.getAdverts().subscribe(res => this.adverts = res, error => console.log(error.error.error));
  }

}
