import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { TokenService } from '../token.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { UserService } from '../user.service';
import { SnotifyService } from 'ng-snotify';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  className = 'login';
  emailMessage = false;
  loginLoader = false;
  // buttonState = false;
  message = null;

  constructor(private api: ApiService, private token: TokenService, private router: Router, private Auth: AuthService, private user: UserService, private notify: SnotifyService) { }

  ngOnInit() {
    // if(window.location.href.includes('login')) {
    //   alert('login page')
    // }
    // else {
    //   alert('other pages');
    // }
  }

  tokenObject = {
    access_token: null
  }

  handleResponse(data) {
    this.tokenObject = data;
    this.token.handle(this.tokenObject.access_token);

    this.Auth.changeAuthStatus(true);



    this.router.navigateByUrl('/');
    // window.location.href = '/dashboard';
  }

  submit(data) {

    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(re.test(data.email)) {
      this.emailMessage = false;

      this.loginLoader = true;

      this.api.login(data).subscribe(
        (response) => {
          let data = response;
          localStorage.setItem('status', JSON.stringify(response));

          let statusData = localStorage.getItem('status');

          let a = JSON.parse(statusData);
          if(a.status == 'blocked') {
            this.loginLoader = false;
            alert('Your account has been blocked!!');
            return false;
          }
          if(a.status == 'suspended') {
            this.loginLoader = false;
            alert('Your account has been suspended!!');
            return false;
          }

          localStorage.removeItem('status');

          console.log(response)
          this.api.getLoggedInUser(response).subscribe((response) => {

            localStorage.setItem('user', JSON.stringify(response));

            this.loginLoader = false;
            this.handleResponse(data);

          }, (error) => {
            this.loginLoader = false;
            // alert(error.error.error);
            alert(error.error.error);
          });


        },
        (error) => {
          // alert(error.error.error);
          alert(error.error.error);
          this.loginLoader = false;
        }
      );


    }
    else {
      this.emailMessage = true;
    }


  }

}
