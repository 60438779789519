import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import {SnotifyService} from 'ng-snotify';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  loader = false;
  public form = {
    email: null
  };

  constructor(private api: ApiService, private notify: SnotifyService) { }

  ngOnInit() {
  }

  submit(data) {
    this.loader = true;
    this.api.sendPasswordResetLink(data).subscribe(
      data => this.handleResponse(data),
      (error) => {
        // alert(error.error.error);
        // this.notify.error(error.error.error, {timeout: 5000});
        this.loader = false;
      }
    );
  }

  handleResponse(response) {
    this.loader = false;
    console.log(response);
    // this.notify.success(response.data, {timeout: 5000});
    alert(response.data);

    this.form.email = null;

  }

}
