import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private api: ApiService) { }

  adverts = null;
  ngOnInit() {
    this.api.getAdverts().subscribe(res => this.adverts = res, error => console.log(error.error.error));
  }

}
