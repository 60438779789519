import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router, private api: ApiService) {
    this.user = JSON.parse(localStorage.getItem('user'));
    console.log(this.user);
    if(this.user.user_type == 'player') {
      this.api.getPlayerProfile(this.user.id).subscribe(response => {
        this.userInfo = response;
        console.log(this.userInfo);
      });
    }
    if(this.user.user_type == 'coach') {
      this.api.getCoachProfile(this.user.id).subscribe(response => {
        this.userInfo = response;
        console.log(this.userInfo);
      });
    }

    if(this.user.user_type == 'trainer') {
      this.api.getTrainerProfile(this.user.id).subscribe(response => {
        this.userInfo = response;
        console.log(this.userInfo);
      });
    }
  }






  user = null;
  userInfo = null;
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    console.log(this.user);
    if(this.user.user_type == 'player') {
      this.api.getPlayerProfile(this.user.id).subscribe(response => {
        this.userInfo = response;
        console.log(this.userInfo);
      });
    }
    else {
      this.api.getCoachProfile(this.user.id).subscribe(response => {
        this.userInfo = response;
        console.log(this.userInfo);
      });
    }

  }

  navigateTo(url) {
    if(url == 'profile') {
      let user = JSON.parse(localStorage.getItem('user'));

      if(user.user_type == 'player') {
        this.router.navigateByUrl('player-profile')
      }
      if(user.user_type =='coach')
      {
        this.router.navigateByUrl('coach-profile');
      }
      if(user.user_type =='trainer')
      {
        this.router.navigateByUrl('trainer-profile');
      }
    }
    else {
      this.router.navigateByUrl('chat');
    }


  }

}
