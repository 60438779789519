import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SnotifyService } from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private api: ApiService, private notify: SnotifyService) { }

  getLoggedInUser() {
    let user = null;
    let token = localStorage.getItem('token');
    this.api.getLoggedInUser(token).subscribe((response) => {

      localStorage.setItem('user', JSON.stringify(response));

    }, (error) => {
      this.notify.error(error.error.error, {timeout: 3000});
    });
  }

  user() {

  }

  userInfo() {

  }

}
