import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router, Event } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  constructor(private api: ApiService, private router: Router, private notify: SnotifyService) {

    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {

        clearInterval(this.apiInterval);

      }

    });

  }

  userInfo = null;
  message = null;
  chats = null;
  userDetails = null;
  selectedChatId = 0;
  selectedChat = null;

  ngOnInit() {
    let userData = JSON.parse(localStorage.getItem('user'));

    this.api.getLoggedInUserInfo(userData.user_type, userData.id).subscribe(res => {
      this.userDetails = res;
      console.log(this.userDetails);
    }, error => {
      console.log(error);
    });

    this.userInfo = userData;

    this.api.getChats(this.userInfo.id).subscribe(res => {
      this.chats = res;
      console.log(this.chats);
    }, error => {console.log(error.error)});
  }


  goToDashboard() {
    this.router.navigateByUrl('/dashboard');
  }

  onKeyUp(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  messages = null;
  messageLoader = false;

  apiInterval = null;

  getMessages(chat) {
    this.messages = null;
      this.messageLoader = true;
      this.selectedChatId = chat.id;
      this.selectedChat = chat;
      clearInterval(this.apiInterval);

    this.apiInterval = setInterval(() => {


      this.api.getMessages(chat.user_id, chat.receiver_id).subscribe(res => {
        this.messages = res;
        this.messageLoader = false;
        var element = document.getElementById('messages');
        element.scrollTop = 1000000000;
      }, error => {
        console.log(error);
        this.messageLoader = false;
      });
    }, 4000);


  }

  sendMessage() {
    if(!this.message) {
      this.notify.warning('Type a message', {timeout: 1000, pauseOnHover: false})
      return false;
    }
    let data = {
      user_id: this.selectedChat.user_id,
      receiver_id: this.selectedChat.receiver_id,
      message: this.message
    }

    this.message = null;

    this.api.sendMessage(data).subscribe(response => {
      this.messages.push(response);
      var element = document.getElementById('messages');
      element.scrollTop = 1000000000;

      new Audio('/jockify/assets/audio/sent.mp3').play();
    }, error => {
      console.log(error);
    });
  }

}
