import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AddProfileComponent } from './add-profile/add-profile.component';
import { PlayerComponent } from './player/player.component';

import { CoachComponent } from './coach/coach.component';
import { FaqComponent } from './faq/faq.component';
import { HelpComponent } from './help/help.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AboutComponent } from './about/about.component';
import { AdvertComponent } from './advert/advert.component';
import { ForgotComponent } from './forgot/forgot.component';
import { AddPlayerProfileComponent } from './add-player-profile/add-player-profile.component';
import { AddCoachProfileComponent } from './add-coach-profile/add-coach-profile.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PlayerInfoComponent } from './player-info/player-info.component';
import { CoachInfoComponent } from './coach-info/coach-info.component';
import { ChatComponent } from './chat/chat.component';
import { TermsComponent } from './terms/terms.component';
import { PrivateTrainerComponent } from './private-trainer/private-trainer.component';
import { AddPrivateTrainerComponent } from './add-private-trainer/add-private-trainer.component';
import { PrivateTrainerInfoComponent } from './private-trainer-info/private-trainer-info.component';


@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HomeComponent,
    AddProfileComponent,
    PlayerComponent,
    CoachComponent,
    FaqComponent,
    HelpComponent,
    LoginComponent,
    RegisterComponent,
    PageNotFoundComponent,
    AdvertComponent,
    ForgotComponent,
    AddPlayerProfileComponent,
    AddCoachProfileComponent,
    DashboardComponent,
    NavbarComponent,
    FooterComponent,
    ResetPasswordComponent,
    PlayerInfoComponent,
    CoachInfoComponent,
    ChatComponent,
    TermsComponent,
    PrivateTrainerComponent,
    AddPrivateTrainerComponent,
    PrivateTrainerInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatProgressBarModule,
    SnotifyModule

  ],
  providers: [
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
