import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http : HttpClient) { }

  rootUrl = 'https://backend.jockify.com/api';

  // rootUrl = 'http://localhost:8000/api';

  getAdverts() {

    let url = this.rootUrl+'/get-adverts';
    return this.http.get(url);
  }

  login(data) {
    let url = this.rootUrl+'/login';
    return this.http.post(url, data);
  }

  register(data) {
    let url = this.rootUrl+'/register';
    return this.http.post(url, data);
  }


  getUserInfo(userType, id) {
    let url = this.rootUrl+'/get-user-info/'+userType+'/'+id;
    return this.http.get(url);
  }

  getLoggedInUserInfo(userType, userId) {
    let url = this.rootUrl+'/get-logged-in-user-info/'+userType+'/'+userId;
    return this.http.get(url);
  }

  openChat(data) {
    let url = this.rootUrl+'/open-chat';

    return this.http.post(url, data);
  }

  getChats(userId) {
    let url = this.rootUrl+'/get-chats/'+userId;
    return this.http.get(url);
  }

  sendMessage(data) {
    let url = this.rootUrl+'/send-message';

    return this.http.post(url, data);
  }

  getMessages(userId, receiverId) {
    let url = this.rootUrl+'/get-messages/'+userId+'/'+receiverId;
    return this.http.get(url);
  }

  sendPasswordResetLink(data) {
    let url = this.rootUrl+'/send-password-reset-link';
    return this.http.post(url, data);
  }

  changePassword(data) {
    let url = this.rootUrl+'/edit-password';
    let token = localStorage.getItem('token');

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${token}`)
    }

    return this.http.post(url, data, header);
  }



  changeUserPassword(data) {
    let url = this.rootUrl+'/edit-user-password';
    let token = localStorage.getItem('token');

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${token}`)
    }

    return this.http.post(url, data, header);
  }

  getLoggedInUser(obj) {
    let url = this.rootUrl+'/me';


    let data = {
      token: obj.access_token
    };


    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${obj.access_token}`)
    }

    return this.http.post(url, data, header);
  }

  getPlayerProfile(userId) {
    let url = this.rootUrl+'/get-player-profile/'+userId;

    return this.http.get(url);
  }


  getCoaches() {
    let url = this.rootUrl+'/get-coaches';

    return this.http.get(url);
  }

  getTrainers() {
    let url = this.rootUrl+'/get-trainers';

    return this.http.get(url);
  }

  getPlayers() {
    let url = this.rootUrl+'/get-players';

    return this.http.get(url);
  }




  getCoachProfile(userId) {
    let url = this.rootUrl+'/get-coach-profile/'+userId;

    return this.http.get(url);
  }

  getTrainerProfile(userId) {
    let url = this.rootUrl+'/get-trainer-profile/'+userId;

    return this.http.get(url);
  }

  uploadImage(data) {
    let url = this.rootUrl+'/upload-image';

    let token = localStorage.getItem('token');

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${token}`)
    }

    return this.http.post(url, data, header);
  }

  updatePlayerProfile(data) {
    let token = localStorage.getItem('token');

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${token}`)
    }

    let url = this.rootUrl+'/update-player-profile';

    return this.http.post(url, data, header);
  }


  updateCoachProfile(data) {
    let token = localStorage.getItem('token');

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${token}`)
    }

    let url = this.rootUrl+'/update-coach-profile';

    return this.http.post(url, data, header);
  }


  updateTrainerProfile(data) {
    let token = localStorage.getItem('token');

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${token}`)
    }

    let url = this.rootUrl+'/update-trainer-profile';

    return this.http.post(url, data, header);
  }


}
