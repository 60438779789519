import { Component, OnInit } from '@angular/core';
import { Router, Event } from '@angular/router';
import { NavigationStart, NavigationError, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();
  currentUrl = '';

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {


      if (event instanceof NavigationEnd) {
          //do something on end activity

          this.currentUrl = this.router.url.substring(1);

      }


    });
   }

  ngOnInit() {
  }

}
