import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private api: ApiService) { }
  adverts = null;

  ngOnInit() {
    this.api.getAdverts().subscribe(res => this.adverts = res, error => console.log(error.error.error));

  }

}
