import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  loader = null;
  emailMessage = false;
  message = null;
  token = null;

  public form = {

  }

  constructor(private route: ActivatedRoute, private router: Router, private api: ApiService, private notify: SnotifyService) {
    route.queryParams.subscribe(params => {
      this.token = params['token'];
    });

  }

  ngOnInit() {

  }

  submit(data) {
    if(data.password != data.confirm_password) {
      this.message = 'Passwords do not match.';
      return false;
    }
    else {
      this.message = null;
    }
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(re.test(data.email)) {
      this.emailMessage = false;

      this.loader = true;
      data.token = this.token;
      this.api.changePassword(data).subscribe(
        (response) => {

          this.loader = false;
          this.handleResponse(response);

        },
        (error) => {

          this.loader = false;
          this.handleError(error);
        }
      );


    }
    else {
      this.emailMessage = true;
    }
  }

  handleResponse(response) {
    let _router = this.router;

    if(confirm('Password changed, Login now?')) {
      _router.navigateByUrl('/login');
    }

    // this.notify.confirm('Password changed', 'Successfull', {
    //   timeout: 5000,
    //   showProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   buttons: [
    //     {text: 'OK', action: (toast) => {_router.navigateByUrl('/login'); this.notify.remove(toast.id); } }
    //   ]
    // });


  }

  handleError(error) {
    alert(error.error.error);
    // this.notify.error(error.error.error, {timeout: 5000});
  }



}
